<template>
    <styled-interface no-padding>
        <template #interface-toolbar-external>
            <dealer-toolbar-global
                hide-interval
                hide-attribution
                hide-campaigns
                :settings-open.sync="settingsSlideoutOpen" />
        </template>

        <template>
            <div class="layout wrap">
                <global-alert />

                <div class="dealer-status">
                    <div class="item account">
                        <p class="label">
                            Account:
                        </p>
                        <p class="value">
                            {{ currentDealer.name }}
                        </p>
                    </div>
                    <div
                        v-if="!userIsClient"

                        class="item product">
                        <p class="label">
                            Active Products:
                        </p>
                        <p class="value">
                            {{ productsFormatted }}
                        </p>
                    </div>
                    <div class="item deployment">
                        <p class="label">
                            Active Deployment:
                        </p>
                        <p
                            v-if="activeDeployment"
                            class="value">
                            <a
                                href="#"
                                @click.prevent="gotoOnboarding">{{ activeDeployment.label }}</a>
                        </p>
                        <p
                            v-else
                            class="value">
                            None at this time
                        </p>
                    </div>
                    <div class="item actions">
                        <action-button
                            v-if="!hideSpend"
                            class="mx-2"
                            icon="pdf"
                            @click="showGenerateReportSlideout = true">
                            Generate
                        </action-button>
                        <!-- <action-button
                                class="mx-2"
                                icon="download"
                                @click="exportCSV()">
                                Export
                            </action-button> -->
                        <action-button
                            v-if="!userIsClient"
                            class="mx-2"
                            icon="cog"
                            :to="{ name: 'dealer' }">
                            Account Settings
                        </action-button>
                    </div>
                </div>
            </div>

            <loader
                v-if="metricsLoading"
                class="mb-5" />

            <no-advertising-data
                v-if="!metricsLoading && !hasInsights"
                channel-name="Snapchat"
                class="ma-4 xs12" />

            <styled-card
                v-if="hasInsights"
                class="flex ma-4 xs12">
                <template #heading>
                    Snapchat Metric Funnel
                </template>
                <template #action-buttons>
                    <action-button
                        v-if="!userIsClient"
                        icon="info"
                        href="https://support.buyerbridge.io/knowledge/omnichannel-ad-metrics"
                        target="_blank">
                        Info
                    </action-button>
                </template>

                <loader
                    v-if="metricsLoading"
                    class="mb-5" />

                <div
                    v-else
                    class="metrics">
                    <div class="left-pane">
                        <funnel
                            v-if="hasInsights"
                            :metric="metric"
                            :metrics="metricTotals.stats"
                            :available-metrics="availableMetrics"
                            @changeMetric="changeMetric" />

                        <div
                            v-if="!hasPreviousPeriodInsights"
                            class="data-errors">
                            <p class="error">
                                Some or all of the data is not available at this time for the specified dates selected.
                            </p>
                        </div>

                        <missing-sales-data
                            v-if="!metricTotals.stats.cps.value"
                            :end-date="endDate"
                            :start-date="startDate"
                            platform="snapchat" />
                    </div>

                    <div class="right-pane">
                        <chart
                            :metric="metric"
                            :metric-series="metricsIntervals"
                            :available-metrics="availableMetrics"
                            :metrics="metrics"
                            @changeMetric="changeMetric" />
                    </div>
                </div>
            </styled-card>

            <generate-report-slideout
                v-model="showGenerateReportSlideout"
                :template-id="SNAPCHAT_REPORT_ID" />

            <styled-card
                v-if="!hideSpend && hasInsights"
                :class="{
                    'mb-4 mx-4 gray-icons': true,
                    'page-break-before': totalOfflineEvents > 0
                }">
                <template #heading>
                    Snapchat Advertising Overview
                </template>

                <!-- <template #action-buttons>
                    <action-button
                        class="mx-2"
                        icon="report-detailed"
                        @click="">
                        View Full Report
                    </action-button>
                </template> -->

                <loader
                    v-if="loadingAccountInsights"
                    class="mb-5" />


                <v-layout
                    v-else-if="metricTotals"
                    class="metric-items px-5 pt-5">
                    <v-flex
                        class="sm2 xs12">
                        <market-stats
                            title="CPM"
                            :metric="metricTotals.stats.cpm.formatted"
                            :metric-value="metricTotals.stats.cpm.value"
                            :previous-metric-value="metricsTotalsPreviousPeriod ? metricsTotalsPreviousPeriod.stats.cpm.value : 0"
                            :show-change="hasPreviousPeriodInsights"
                            :tool-tip-message="$t('metrics.cpm')"
                            :class="{'select-left': leftSelectMetric === 'cpm', 'select-right': rightSelectMetric === 'cpm'}"
                            @click.native="clickMetric('cpm')" />
                    </v-flex>
                    <v-flex
                        class="sm2 xs12">
                        <market-stats
                            title="CPSU"
                            :metric="metricTotals.stats.swipes_cost_per.formatted"
                            :metric-value="metricTotals.stats.swipes_cost_per.value"
                            :previous-metric-value="metricsTotalsPreviousPeriod ? metricsTotalsPreviousPeriod.stats.swipes_cost_per.value : 0"
                            :show-change="hasPreviousPeriodInsights"
                            :tool-tip-message="$t('metrics.cpsu')"
                            :class="{'select-left': leftSelectMetric === 'swipes_cost_per', 'select-right': rightSelectMetric === 'swipes_cost_per'}"
                            @click.native="clickMetric('swipes_cost_per')" />
                    </v-flex>
                    <v-flex
                        class="sm2 xs12">
                        <market-stats
                            title="CPPV"
                            :metric="metricTotals.stats.conversion_page_views_cost_per.formatted"
                            :metric-value="metricTotals.stats.conversion_page_views_cost_per.value"
                            :previous-metric-value="metricsTotalsPreviousPeriod ? metricsTotalsPreviousPeriod.stats.conversion_page_views_cost_per.value : 0"
                            :show-change="hasPreviousPeriodInsights"
                            :tool-tip-message="$t('metrics.cppv')"
                            :class="{'select-left': leftSelectMetric === 'conversion_page_views_cost_per', 'select-right': rightSelectMetric === 'conversion_page_views_cost_per'}"
                            @click.native="clickMetric('conversion_page_views_cost_per')" />
                    </v-flex>
                    <v-flex
                        class="sm2 xs12">
                        <market-stats
                            title="CPCV"
                            :metric="metricTotals.stats.conversion_view_content_cost_per.formatted"
                            :metric-value="metricTotals.stats.conversion_view_content_cost_per.value"
                            :previous-metric-value="metricsTotalsPreviousPeriod ? metricsTotalsPreviousPeriod.stats.conversion_view_content_cost_per.value : 0"
                            :show-change="hasPreviousPeriodInsights"
                            :tool-tip-message="$t('metrics.cpcv')"
                            :class="{'select-left': leftSelectMetric === 'conversion_view_content_cost_per', 'select-right': rightSelectMetric === 'conversion_view_content_cost_per'}"
                            @click.native="clickMetric('conversion_view_content_cost_per')" />
                    </v-flex>
                    <v-flex
                        class="sm2 xs12">
                        <market-stats
                            title="CPL"
                            :metric="metricTotals.stats.conversion_sign_ups_cost_per.formatted"
                            :metric-value="metricTotals.stats.conversion_sign_ups_cost_per.value"
                            :previous-metric-value="metricsTotalsPreviousPeriod ? metricsTotalsPreviousPeriod.stats.conversion_sign_ups_cost_per.value : 0"
                            :show-change="hasPreviousPeriodInsights"
                            :tool-tip-message="$t('metrics.cpl')"
                            :class="{'select-left': leftSelectMetric === 'conversion_sign_ups_cost_per', 'select-right': rightSelectMetric === 'conversion_sign_ups_cost_per'}"
                            @click.native="clickMetric('conversion_sign_ups_cost_per')" />
                    </v-flex>
                    <v-flex
                        v-if="metricTotals.stats.cps.value"
                        class="sm2 xs12">
                        <market-stats
                            title="CPS"
                            :metric="metricTotals.stats.cps.formatted"
                            :metric-value="metricTotals.stats.cps.value"
                            :previous-metric-value="metricsTotalsPreviousPeriod ? metricTotals.stats.cps.value : 0"
                            :show-change="hasPreviousPeriodInsights"
                            :tool-tip-message="$t('metrics.cps')"
                            :class="{'select-left': leftSelectMetric === 'cps', 'select-right': rightSelectMetric === 'cps'}"
                            @click.native="clickMetric('cps')" />
                    </v-flex>
                </v-layout>
                <powered-by :platforms="['snapchat']" />
            </styled-card>

            <styled-card
                v-if="hasInsights"
                :class="{
                    'mb-4 mx-4 gray-icons': true,
                    'page-break-before': totalOfflineEvents > 0
                }">
                <template #heading>
                    Compare Snapchat Metrics
                </template>

                <template #action-buttons>
                    <action-button
                        icon="info"
                        href="https://support.buyerbridge.io/knowledge/omnichannel-ad-metrics"
                        target="_blank">
                        Info
                    </action-button>
                </template>

                <loader
                    v-if="!hasInsights"
                    class="py-5 bb-1-loblolly" />
                <div
                    v-else-if="metricsIntervals.length"
                    class="px-5 pb-5 pt-4 advertising-graph">
                    <metric-comparison-chart
                        :left-metric="leftSelectMetric"
                        :right-metric="rightSelectMetric"
                        :metrics-chart-data="metricsIntervals"
                        :should-show-cps="!!metricTotals.stats.cps.value"
                        @update="updateMetric" />
                </div>

                <powered-by :platforms="['snapchat']" />
            </styled-card>
        </template>
    </styled-interface>
</template>

<script>
import DealerToolbarGlobal from '@/components/globals/DealerToolbarGlobal';
import GenerateReportSlideout from '@/components/report-builder/GenerateReportSlideout';
import GlobalAlert from '@/components/globals/GlobalAlert';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import MarketStats from '@/components/globals/MarketStats';
import Funnel from '@/components/dealers/dashboards/snapchat/cards/Funnel';
import Chart from '@/components/dealers/dashboards/snapchat/cards/Chart';
import Loader from '@/components/globals/Loader';
import MetricComparisonChart from '@/components/dealers/dashboards/snapchat/cards/MetricComparisonChart';
import PoweredBy from '@/components/globals/PoweredBy';
import NoAdvertisingData from '@/components/dealers/dashboards/NoAdvertisingData';
import MissingSalesData from '@/components/globals/MissingSalesData';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import { SET_FB_DEALER_CAMPAIGNS_SELECTED } from '@/store/mutation-types';
import { SET_INTERVAL } from '@/store/mutation-types';
import { SNAPCHAT_REPORT_ID } from '@/helpers/globals';


export default {
    name: 'SnapchatDashboard',
    title: 'Snapchat Dashboard',
    components: {
        DealerToolbarGlobal,
        GlobalAlert,
        ActionButton,
        MarketStats,
        Loader,
        Funnel,
        Chart,
        MetricComparisonChart,
        MissingSalesData,
        GenerateReportSlideout,
        StyledInterface,
        StyledCard,
        PoweredBy,
        NoAdvertisingData
    },
    data() {
        return {
            errors: [],
            toolbarFixed: false,
            toolbarHeight: 0,
            leftSelectMetric: '',
            rightSelectMetric: '',
            compareSelect: 0,
            showMoreCampaigns: false,
            highlightOfflineEvents: false,
            isResetting: false,
            showMetricInfo: false,
            totalOfflineEvents: 0,
            settingsSlideoutOpen: false,
            showGenerateReportSlideout: false,
            metric: {},
            SNAPCHAT_REPORT_ID,
            availableMetrics: [
                {
                    id: 'impressions',
                    name: 'Impressions',
                    value: 'impressions',
                    cost: {
                        key: 'spend',
                        name: 'Spend'
                    }
                },
                {
                    id: 'swipes',
                    name: 'Swipe Ups',
                    value: 'swipes',
                    cost: {
                        key: 'swipes_cost_per',
                        name: 'Cost / Swipe Up'
                    }
                },
                {
                    id: 'leads',
                    name: 'Leads',
                    value: 'conversion_sign_ups',
                    cost: {
                        key: 'conversion_sign_ups_cost_per',
                        name: 'Cost / Lead'
                    }
                },
                {
                    id: 'conversion_purchases',
                    name: 'Sales',
                    value: 'conversion_purchases',
                    cost: {
                        key: 'cps',
                        name: 'Cost / Sale'
                    }
                }
            ]
        };
    },
    computed: {
        ...mapGetters([
            'userIsClient',
            'dealerProducts',
            'dealerHasAdProduct',
            'dealerAdSpendOverride',
            'activeDealerOnboardings',
            'dealerFacebookAdAccountUrlFiltered'
        ]),
        ...mapGetters('metrics/snapchat/ads', {
            metricsLoading: 'metricsLoading',
            hasInsights: 'hasMetrics',
            hasPreviousPeriodInsights: 'hasPreviousPeriodMetrics',
        }),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend'
        }),
        metrics() {
            return {
                average90Day: this.metricsTotalsPrevious90Days,
                previousMonth: this.metricsTotalsPreviousMonth,
                previousPeriod: this.metricsTotalsPreviousPeriod,
                selected: this.metricsTotals,
                series: this.metricsIntervals
            };
        },
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentDealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user,
            dateRange: (state) => state.metrics.dateRange,
            attribution: (state) => state.metrics.attribution,

            // Metric state
            campaigns: (state) => state.metrics.snapchat.ads.campaigns,
            campaignsSelected: (state) => state.metrics.snapchat.ads.campaignsSelected,
            loadingCampaigns: (state) => state.metrics.snapchat.ads.campaignsLoading,
            loadingInsights: (state) => state.metrics.snapchat.ads.dealerInsightsLoading,
            loadingAccountInsights: (state) => state.metrics.snapchat.ads.metricsTotalsLoading,
            loadingCampaignInsights: (state) => state.metrics.snapchat.ads.metricsCampaignsLoading,
            metricsIntervals: (state) => state.metrics.snapchat.ads.metricsIntervals,
            metricTotals: (state) => state.metrics.snapchat.ads.metricsTotals,
            metricsTotals: (state) => state.metrics.snapchat.ads.metricsTotals,
            metricsTotalsPreviousPeriod: (state) => state.metrics.snapchat.ads.metricsTotalsComparison,
            metricsTotalsPreviousMonth: (state) => state.metrics.snapchat.ads.metricsTotalsPreviousMonth,
            metricsTotalsPrevious90Days: (state) => state.metrics.snapchat.ads.metricsTotalsPrevious90Days,
        }),
        pageTitle() {
            if (!this.currentDealer) {
                return null;
            }

            return `Snapchat Dashboard - ${this.currentDealer.name} - ${this.startDate} to ${this.endDate}`;
        },
        productsFormatted() {
            let names = this.dealerProducts
                .filter(p => p.platform_id === 9)
                .map(p => p.display_name);

            if(names.length === 1) {
                return names[0];
            } else if (names.length === 2) {
                return names.join(', ');
            }

            return names;
        },
        activeDeployment() {
            if(this.activeDealerOnboardings.length > 0) {
                return this.activeDealerOnboardings[0];
            }
            return false;
        },
        startDate() {
            return moment(this.dateRange.startDate).format('ll');
        },
        endDate() {
            return moment(this.dateRange.endDate).format('ll');
        }
    },
    watch: {
        pageTitle: {
            handler(value) {
                this.$title = value;
            },
            immediate: true
        },
        currentDealerId() {

            // Watch on the dealer ID since this doesn't show up
            // for non-advertising dealers
            if (this.$refs.toolbar) {
                this.toolbarHeight = this.$refs.toolbar.offsetHeight;
            }
        },
        dealerAdSpendOverride: {
            handler() {
                this.setDefaultMetricSelections();
            },
            immediate: true
        },
        hideSpend() {
            this.setDefaultMetricSelections();
        }
    },
    mounted() {
        if(this.$route.query.view_offline_events) {
            this.$scrollTo(document.getElementById('offlineEventsTarget'), 2, { easing: 'ease-in', offset: 300 });
        }

        this.metric = this.availableMetrics[0];
    },
    activated() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
        this.$title = this.pageTitle;
        this.$store.commit(SET_INTERVAL, 'day');
    },
    deactivated() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        setDefaultMetricSelections() {
            this.leftSelectMetric = this.dealerAdSpendOverride?.type === 'no_spend' ? 'swipes' : 'swipes_cost_per';
            this.rightSelectMetric = this.dealerAdSpendOverride?.type === 'no_spend' ? 'impressions' : 'spend';
        },
        openAdAccount() {
            const url = this.dealerFacebookAdAccountUrlFiltered({
                dateRange: this.dateRange,
                campaigns: this.campaignsSelected.map(campaign => campaign.id)
            });
            window.open(url);
        },
        updateMetric(v) {
            this.leftSelectMetric = v[0];
            this.rightSelectMetric = v[1];
        },
        clickMetric(metric_id) {
            if (this.compareSelect === 0) {
                if (this.rightSelectMetric === metric_id)
                    return;

                this.leftSelectMetric = metric_id;
                this.compareSelect = !this.compareSelect;
            } else {
                if (this.leftSelectMetric === metric_id)
                    return;

                this.rightSelectMetric = metric_id;
                this.compareSelect = !this.compareSelect;
            }
        },
        changeMetric(metric) {
            this.metric = metric;
        },
        unselectCampaign(unselectedCampaign) {

            const newCampaigns = this.campaignsSelected.filter(campaign => {
                return campaign.id !== unselectedCampaign.id;
            });

            this.$store.commit(SET_FB_DEALER_CAMPAIGNS_SELECTED, newCampaigns);
        },

        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Campaign Stats',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `campaign_stats_${new Date().getTime()}.csv`
            };

            const csvExporter = new ExportToCsv(options);
            const exportData = this.campaignStatsData.map(campaign => {
                const newData = {};
                for (let metric in campaign) {
                    newData[metric] = campaign[metric].formatted;
                }
                return newData;
            });

            csvExporter.generateCsv(exportData);
        },

        exportOverallStatsCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Campaign Stats',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `campaign_stats_${new Date().getTime()}.csv`
            };

            const csvExporter = new ExportToCsv(options);
            const exportData = this.metricsIntervals.map(campaign => {
                const newData = {};
                newData['dealer_name'] = this.currentDealer.name;
                for (let metric in campaign) {
                    if(!['campaign_name', 'campaign_id', 'product_id'].includes(metric)) {
                        newData[metric] = campaign[metric].formatted;
                    }
                }
                return newData;
            });

            csvExporter.generateCsv(exportData);
        },

        handleScroll() {
            const elementTarget = this.$refs.toolbar;
            if (!elementTarget) {
                return;
            }
            if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
                this.toolbarFixed = true;
            } else {
                this.toolbarFixed = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$breakpoint: 1330px;
.metrics {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint) {
        flex-direction: row;
    }

    .left-pane {
        display: flex;
        position: relative;
        flex-direction: column;
        border-bottom: 1px solid #d8d8d8;
        padding-top: 45px;
        flex: 1;
        width: 100%;

        @media (min-width: $breakpoint) {
            border-bottom: none;
            border-right: 1px solid #d8d8d8;
            width: 60%;
        }
    }
    .right-pane {
        width: 100%;
        @media (min-width: $breakpoint) {
            width: 40%;
        }
    }

    .footer {
        border-top: 1px solid #d8d8d8;
    }
}

.toolbar-fixed {
    .v-autocomplete.v-input > .v-input__control > .v-input__slot {
        height: 33px;
        margin-bottom: 0;
    }
    .v-text-field__details {
        display: none;
    }
}

.campaign-select {
    .campaign-selections {
        background-color: $gray;
        color: $white;
        padding: 2px 6px;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        border-radius: 3px;
    }
    input {
        &::placeholder {
            color: $gray !important;
        }
    }
    .v-input--has-selection.v-input--is-focused {
        input {
            margin-left: 10px;
        }
    }
    .v-input--is-dirty:not(.v-input--is-focused),
    .v-input--has-selection:not(.v-input--is-focused) {
        input {
            opacity: 0;
        }
    }
}

.dealer-status {
    margin: 15px 30px 0;
    width: 100%;
    @media (min-width: $bp-sm) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .item {
        margin: 0 0 20px 0;
        @media (min-width: $bp-sm) {
            margin: 0 50px 0 0;
        }
    }
    .actions {
        margin-left: auto;
        margin-right: 0px;
        .action-button {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .label {
        font-size: 13px;
    }
    .value {
        font-size: 16px;
        color: #00a2ea;
        margin-top: -18px;
    }
}

.toolbar {
    transition: none;
    transform: translateY(0px);
    background-color: transparent;
    .toolbar-heading {
        align-self: start;
        h2 {
            font-size: 1.3rem;
        }
    }
}

.toolbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    padding: 20px;
    background-color: #f7f7f7;
    align-items: flex-end;
    transform: translateY(89px);
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 0 10px rgba(black, 0.5);
    .toolbar-heading {
        display: none;
    }
}

.metric-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .select-left  {
        outline:2px solid #7FDDFE !important;
        outline-offset: -10px;
    }
    @media (min-width: 960px) {
        .custom {
            flex-basis: calc(100%/7) !important;
            flex-grow: 0;
            max-width: calc(100%/7) !important;
        }
    }

    .select-right  {
        outline:2px solid #E350B4 !important;
        outline-offset: -7px;
    }
}

@media print {
    .toolbar-container {
        display: none;
    }
    .advertising-graph-toolbar {
        padding: 0 !important;
    }
    .advertising-graph {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-bottom: 0 !important;
    }
    .metric-items {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .stats-element {
            outline: none !important;
        }
    }
    .page-break-before {
        page-break-before: always;
    }
    .no-offline-events {
        display: none;
    }
}

.powered-by-container {
    width: 100%;
    text-align: right;
}

.data-errors {
    padding: 15px;
    position: relative;
    background: $coral;
    .error {
        margin-bottom: 0;
        color: $black;
        background: transparent !important;
    }
    ul {
        margin: 0;
        list-style: none;
    }
    .platform {
        font-weight: 700;
        text-align: left;
        color: $black;
        margin-left: 15px;
    }

}
</style>
